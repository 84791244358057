import { lazy } from "react";

export const Search = lazy(() => import("./Search"));
export const Folder = lazy(() => import("./Folder"));
export const Wallet = lazy(() => import("./Wallet"));
export const Graph = lazy(() => import("./Graph"));
export const Bookmark = lazy(() => import("./Bookmark"));
export const Category = lazy(() => import("./Category"));
export const Home = lazy(() => import("./Home"));
export const Notification = lazy(() => import("./Notification"));
export const Chat = lazy(() => import("./Chat"));
export const Heart = lazy(() => import("./Heart"));
export const Heart2 = lazy(() => import("./Heart2"));
export const Paper = lazy(() => import("./Paper"));
export const PaperPlus = lazy(() => import("./PaperPlus"));
export const PaperNegative = lazy(() => import("./PaperNegative"));
export const PaperFail = lazy(() => import("./PaperFail"));
export const PaperDownload = lazy(() => import("./PaperDownload"));
export const PaperUpload = lazy(() => import("./PaperUpload"));
export const Send = lazy(() => import("./Send"));
export const Password = lazy(() => import("./Password"));
export const Swap = lazy(() => import("./Swap"));
export const Work = lazy(() => import("./Work"));
export const Scan = lazy(() => import("./Scan"));
export const Activity = lazy(() => import("./Activity"));
export const Calendar = lazy(() => import("./Calendar"));
export const Message = lazy(() => import("./Message"));
export const Video = lazy(() => import("./Video"));
export const Plus = lazy(() => import("./Plus"));
export const Chart = lazy(() => import("./Chart"));
export const Game = lazy(() => import("./Game"));
export const Bag = lazy(() => import("./Bag"));
export const Bag2 = lazy(() => import("./Bag2"));
export const TicketStar = lazy(() => import("./TicketStar"));
export const MoreCircle = lazy(() => import("./MoreCircle"));
export const MoreSquare = lazy(() => import("./MoreSquare"));
export const Discount = lazy(() => import("./Discount"));
export const Buy = lazy(() => import("./Buy"));
export const InfoCircle = lazy(() => import("./InfoCircle"));
export const InfoSquare = lazy(() => import("./InfoSquare"));
export const Danger = lazy(() => import("./Danger"));
export const CloseSquare = lazy(() => import("./CloseSquare"));
export const TickSquare = lazy(() => import("./TickSquare"));
export const Discovery = lazy(() => import("./Discovery"));
export const Location = lazy(() => import("./Location"));
export const Document = lazy(() => import("./Document"));
export const Setting = lazy(() => import("./Setting"));
export const TimeSquare = lazy(() => import("./TimeSquare"));
export const TimeCircle = lazy(() => import("./TimeCircle"));
export const VolumeUp = lazy(() => import("./VolumeUp"));
export const VolumeDown = lazy(() => import("./VolumeDown"));
export const VolumeOff = lazy(() => import("./VolumeOff"));
export const Star = lazy(() => import("./Star"));
export const Ticket = lazy(() => import("./Ticket"));
export const Camera = lazy(() => import("./Camera"));
export const User = lazy(() => import("./User"));
export const AddUser = lazy(() => import("./AddUser"));
export const TwoUsers = lazy(() => import("./TwoUsers"));
export const People = lazy(() => import("./People"));
export const Login = lazy(() => import("./Login"));
export const Logout = lazy(() => import("./Logout"));
export const Download = lazy(() => import("./Download"));
export const Upload = lazy(() => import("./Upload"));
export const Voice = lazy(() => import("./Voice"));
export const Voice2 = lazy(() => import("./Voice2"));
export const Delete = lazy(() => import("./Delete"));
export const Edit = lazy(() => import("./Edit"));
export const EditSquare = lazy(() => import("./EditSquare"));
export const Play = lazy(() => import("./Play"));
export const ShieldDone = lazy(() => import("./ShieldDone"));
export const ShieldFail = lazy(() => import("./ShieldFail"));
export const Show = lazy(() => import("./Show"));
export const Hide = lazy(() => import("./Hide"));
export const Filter = lazy(() => import("./Filter"));
export const Filter2 = lazy(() => import("./Filter2"));
export const Image = lazy(() => import("./Image"));
export const Image2 = lazy(() => import("./Image2"));
export const Call = lazy(() => import("./Call"));
export const Calling = lazy(() => import("./Calling"));
export const CallMissed = lazy(() => import("./CallMissed"));
export const CallSilent = lazy(() => import("./CallSilent"));
export const Lock = lazy(() => import("./Lock"));
export const Unlock = lazy(() => import("./Unlock"));
export const ChevronDownCircle = lazy(() => import("./ChevronDownCircle"));
export const ChevronRightCircle = lazy(() => import("./ChevronRightCircle"));
export const ChevronUpCircle = lazy(() => import("./ChevronUpCircle"));
export const ChevronLeftCircle = lazy(() => import("./ChevronLeftCircle"));
export const ChevronDown = lazy(() => import("./ChevronDown"));
export const ChevronRight = lazy(() => import("./ChevronRight"));
export const ChevronUp = lazy(() => import("./ChevronUp"));
export const ChevronLeft = lazy(() => import("./ChevronLeft"));
export const ArrowDownSquare = lazy(() => import("./ArrowDownSquare"));
export const ArrowRightSquare = lazy(() => import("./ArrowRightSquare"));
export const ArrowUpSquare = lazy(() => import("./ArrowUpSquare"));
export const ArrowLeftSquare = lazy(() => import("./ArrowLeftSquare"));
export const ArrowDown = lazy(() => import("./ArrowDown"));
export const ArrowRight = lazy(() => import("./ArrowRight"));
export const ArrowUp = lazy(() => import("./ArrowUp"));
export const ArrowLeft = lazy(() => import("./ArrowLeft"));
export const CaretRight = lazy(() => import("./CaretRight"));
export const CaretDown = lazy(() => import("./CaretDown"));
export const CaretUp = lazy(() => import("./CaretUp"));
export const CaretLeft = lazy(() => import("./CaretLeft"));
