/// <reference types="vite/client" />
const config = {
  url_api: import.meta.env.VITE_URL_API,
  FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
  zoom_meeting_origin: import.meta.env.VITE_ZOOM_MEETING_ORIGN,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: import.meta.env
    .VITE_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_MESSAGING_FCM_KEY: import.meta.env.VITE_FIREBASE_MESSAGING_FCM_KEY,
  FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
  FIREBASE_VAPID_KEY: import.meta.env.VITE_FIREBASE_VAPID_KEY,
  FLUTTERWAVE_PUBLIC_KEY: import.meta.env.VITE_FLUTTERWAVE_PUBLIC_KEY,
  VITE_PAYSTACK_PUBLIC_KEY: import.meta.env.VITE_PAYSTACK_PUBLIC_KEY,
  AKOMA_HEALTH_SUPPORT_EMAIL: import.meta.env.VITE_AKOMA_HEALTH_SUPPORT_EMAIL,
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  PUBLIC_POSTHOG_KEY: import.meta.env.VITE_PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  VIRTUAL_NODE_ENV: import.meta.env.VITE_VIRTUAL_NODE_ENV,
  AES_ENCRYPTION_KEY: import.meta.env.VITE_AES_ENCRYPTION_KEY,
  APP_CHAT_SESSION_TIMEOUT_MINUTE:
    import.meta.env.VITE_APP_CHAT_SESSION_TIMEOUT_MINUTE || 1,
  APP_INACTIVE_SESSION_TIMEOUT_MINUTE:
    import.meta.env.VITE_APP_INACTIVE_SESSION_TIMEOUT_MINUTE || 30,
  APP_LAUNCH_SESSION_TIMEOUT_MINUTE:
    import.meta.env.VITE_APP_APP_LAUNCH_SESSION_TIMEOUT_MINUTE || 60,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  PROD: import.meta.env.PROD,
  DEV: import.meta.env.DEV,
  SENTRY_REPLAY_SESSION_RATE: import.meta.env.VITE_SENTRY_REPLAY_SESSION_RATE,
  SENTRY_REPLAY_ERROR_RATE: import.meta.env.VITE_SENTRY_REPLAY_ERROR_RATE,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
};
export default config;
