import posthog from "posthog-js";

const onCapture = (name: string, property: object) => {
  return posthog.capture(name, property);
};

const onIdentify = (name: string, property: object) => {
  return posthog.identify(name, property);
};

const onGroup = (company: string) => {
  return posthog.group("company", company);
};

const onReset = () => {
  return posthog.reset();
};

export { onCapture, onIdentify, onReset, onGroup };
