import { routeEnum } from "constants/RouteConstants";
import useFeatureFlagEnabled from "hooks/useFeatureFlagEnabled";
import { Navigate } from "react-router-dom";

function FeatureFlagGuard({
  featureFlag,
  element,
}: {
  featureFlag: string;
  element: any;
}) {
  const posthogFlagEnabled = useFeatureFlagEnabled(featureFlag);

  const enabled = !featureFlag || posthogFlagEnabled;

  if (!enabled) {
    return <Navigate to={routeEnum.DASHBOARD} replace />;
  }

  return element;
}

export default FeatureFlagGuard;
