/* eslint-disable import/order */
import { current } from "@reduxjs/toolkit";

import { uploadImageAndSendChatPromise } from "utils/FileUtils";
import { RtkqTagEnum } from "../constants/strings";
import { akomaHealthApi } from "../store/storeQuerySlice";
import { initializeFirebase } from "configs/firebase.config";

export const ChatApi = akomaHealthApi.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query({
      queryFn: async ({ params }, { dispatch }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();
          const getChats = () => {
            return firestore.getDocs(
              firestore.query(
                firestore.collection(firebaseFirestore, "chats"),
                firestore.where(
                  "participants",
                  "array-contains",
                  params.senderId || ""
                )
              )
            );
          };

          const data: any = [];
          const querySnapshot = await getChats();

          querySnapshot.forEach((document) => {
            data.push({ id: document.id, ...document.data() });
          });

          return { data } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
      providesTags: [{ type: RtkqTagEnum.CHAT }],
    }),
    createChat: builder.mutation({
      queryFn: async ({ data }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();
          const getChat = (senderId, receiverId) => {
            return firestore.getDocs(
              firestore.query(
                firestore.collection(firebaseFirestore, "chats"),
                firestore.or(
                  firestore.where("participants", "==", [senderId, receiverId]),
                  firestore.where("participants", "==", [receiverId, senderId])
                )
              )
            );
          };
          await Promise.all(
            data.users.map(({ id, ...info }) =>
              firestore.setDoc(
                firestore.doc(firebaseFirestore, "users", id),
                info
              )
            )
          );

          const sender = data?.users?.[0];
          const receiver = data?.users?.[1];

          const querySnapshot = await getChat(sender?.id, receiver?.id);
          const finalData = [];
          querySnapshot.forEach((e: any) => finalData.push(e?.id as never));
          if (querySnapshot.empty) {
            const docRef = await firestore.addDoc(
              firestore.collection(firebaseFirestore, "chats"),
              {
                participants: data.users.map(({ id }) => id),
                updatedAt: Date.now(),
                createdAt: Date.now(),
              }
            );
            return { data: { id: docRef.id } } as any;
          }

          return { data: { id: finalData[0] } } as any;
        } catch (error) {
          return { error: { data: { defaultUserMessage: "" } } };
        }
      },
      invalidatesTags: [{ type: RtkqTagEnum.CHAT }],
    }),
    updateChat: builder.mutation({
      queryFn: async ({ path, data }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          const docRef = firestore.doc(firebaseFirestore, "chats", path.id);
          await firestore.updateDoc(docRef, {
            ...data,
            updatedAt: Date.now(),
          });
          const docSnapshot = await firestore.getDoc(docRef);
          if (!docSnapshot.exists()) {
            throw new Error();
          }
          return { data: { id: docRef.id, ...docSnapshot.data() } } as any;
        } catch (error) {
          return { error: { data: { defaultUserMessage: "" } } };
        }
      },
      invalidatesTags: [{ type: RtkqTagEnum.CHAT }],
    }),
    deleteChat: builder.mutation({
      queryFn: async ({ path, data }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();
          const docRef = firestore.doc(firebaseFirestore, "chats", path.id);
          await firestore.deleteDoc(docRef);
          return { data: { id: docRef.id } } as any;
        } catch (error) {
          return { error: { data: { defaultUserMessage: "" } } };
        }
      },
      invalidatesTags: [{ type: RtkqTagEnum.CHAT }],
    }),
    getChatMessages: builder.query({
      queryFn: async ({ path }) => {
        try {
          const data: any = [];

          return { data } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
      async onCacheEntryAdded(
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const { firestore, firebaseFirestore } = await initializeFirebase();

        const q = firestore.query(
          firestore.collection(firebaseFirestore, "chats", path.id, "messages"),
          firestore.orderBy("createdAt", "asc")
        );
        let unsubscribe;
        try {
          await cacheDataLoaded;

          unsubscribe = firestore.onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                updateCachedData((draft) => {
                  draft.push({ id: change.doc.id, ...change.doc.data() });
                });
              }

              if (change.type === "modified") {
                updateCachedData((draft) => {
                  const draftIndex = [...current(draft)].findIndex(
                    (value) => value.id === change.doc.id
                  );

                  draft[draftIndex] = {
                    id: change.doc.id,
                    ...change.doc.data(),
                  };
                });
              }
            });
          });
        } catch {}
        await cacheEntryRemoved;
        unsubscribe();
      },
      providesTags: [{ type: RtkqTagEnum.CHAT_MESSAGE }],
    }),

    getLastChatMessage: builder.query({
      queryFn: async ({ path }) => {
        try {
          const data: any = [];

          return { data } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
      async onCacheEntryAdded(
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const { firestore, firebaseFirestore } = await initializeFirebase();

        const q = firestore.query(
          firestore.collection(firebaseFirestore, "chats", path.id, "messages"),
          firestore.orderBy("createdAt", "asc"),
          firestore.limit(1)
        );
        let unsubscribe;
        try {
          await cacheDataLoaded;

          unsubscribe = firestore.onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                updateCachedData((draft) => {
                  draft.push({ id: change.doc.id, ...change.doc.data() });
                });
              }
            });
          });
        } catch {}
        await cacheEntryRemoved;
        unsubscribe();
      },
      providesTags: [{ type: RtkqTagEnum.LAST_MESSAGE }],
    }),
    sendChatMessage: builder.mutation({
      queryFn: async ({ path, data }, _, extraOptions, baseQuery) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          const chatRef = firestore.doc(firebaseFirestore, "chats", path.id);
          await firestore.updateDoc(chatRef, {
            updatedAt: Date.now(),
          });

          const docRef = await firestore.addDoc(
            firestore.collection(
              firebaseFirestore,
              "chats",
              path.id,
              "messages"
            ),
            {
              ...data,
              unRead: true,
              isReminded: false,
              createdAt: Date.now(),
            }
          );
          const docSnapshot = await firestore.getDoc(docRef);
          if (!docSnapshot.exists()) {
            throw new Error();
          }

          await baseQuery({
            method: "POST",
            url: "/api/v2/users/send-notification",
            data: {
              userId: data?.receiverID,
              title: "Incoming Message",
              body: data?.content || "Document",
            },
          });

          return {
            data: { id: docRef.id, ...docSnapshot.data() },
          } as any;
        } catch (error) {
          return { error: { data: { defaultUserMessage: "" } } };
        }
      },
      invalidatesTags: [{ type: RtkqTagEnum.CHAT }],
    }),
    sendChatEvent: builder.mutation({
      queryFn: async (
        { excludeChatEvent, eventData },
        _,
        extraOptions,
        baseQuery
      ) => {
        try {
          if (!excludeChatEvent) {
            const chatEventTrigger = await baseQuery({
              method: "POST",
              url: "/api/v2/users/chat-event",
              data: { ...eventData },
            });

            return chatEventTrigger;
          }
          return {
            data: {},
          } as any;
        } catch (error) {
          return { error: { data: { defaultUserMessage: "" } } };
        }
      },
    }),
    getChatParticipantsDetails: builder.query({
      queryFn: async ({ path }) => {
        try {
          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
      async onCacheEntryAdded(
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        let unsubscribe;

        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          await cacheDataLoaded;

          const docRef = firestore.doc(firebaseFirestore, "chats", path.id);
          const docSnapshot = await firestore.getDoc(docRef);
          if (!docSnapshot.exists()) {
            throw new Error();
          }
          const { participants } = docSnapshot.data();

          const q = firestore.query(
            firestore.collection(firebaseFirestore, "users"),
            firestore.where("firebaseId", "in", participants)
          );
          unsubscribe = firestore.onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                updateCachedData((draft) => {
                  draft.push({
                    id: change.doc.id,
                    ...change.doc.data(),
                  });
                });
              }

              if (change.type === "modified") {
                updateCachedData((draft) => {
                  const draftIndex = [...current(draft)].findIndex(
                    (value) => value.id === change.doc.id
                  );

                  draft[draftIndex] = {
                    id: change.doc.id,
                    ...change.doc.data(),
                  };
                });
              }
            });
          });
        } catch (error) {}

        await cacheEntryRemoved;
        unsubscribe();
      },
    }),

    getUnreadMessageCount: builder.query({
      queryFn: async ({ path }) => {
        try {
          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
      async onCacheEntryAdded(
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const { firestore, firebaseFirestore } = await initializeFirebase();

        function getChat(senderId, receiverId) {
          return firestore.getDocs(
            firestore.query(
              firestore.collection(firebaseFirestore, "chats"),
              firestore.or(
                firestore.where("participants", "==", [senderId, receiverId]),
                firestore.where("participants", "==", [receiverId, senderId])
              )
            )
          );
        }

        let unsubscribe;

        try {
          await cacheDataLoaded;

          const chatQuerySnapshot = await getChat(
            path.senderId,
            path.receiverId
          );
          const finalData = [];
          chatQuerySnapshot.forEach((e: any) => finalData.push(e?.id as never));
          const chatId = finalData[0];
          // const messagesQuery = firestore.query(
          //   firestore.collectionGroup(firebaseFirestore, "messages"),
          //   // firestore.where("senderId", "==", path.senderId)
          //   // firestore.where("receiverID", "==", path.receiverId)
          //   firestore.where("unRead", "==", true)
          // );

          const messagesQuery = firestore.query(
            firestore.collection(
              firebaseFirestore,
              "chats",
              chatId,
              "messages"
            ),
            firestore.where("senderId", "==", path.senderId),
            firestore.where("receiverID", "==", path.receiverId),
            firestore.where("unRead", "==", true)
          );

          unsubscribe = firestore.onSnapshot(messagesQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                updateCachedData((draft) => {
                  draft.push({ id: change.doc.id, ...change.doc.data() });
                });
              }

              if (change.type === "modified") {
                updateCachedData((draft) => {
                  const draftIndex = [...current(draft)].findIndex(
                    (value) => value.id === change.doc.id
                  );
                  draft[draftIndex] = {
                    id: change.doc.id,
                    ...change.doc.data(),
                  };
                });
              }

              if (change.type === "removed") {
                updateCachedData((draft) => {
                  const draftIndex = [...current(draft)].findIndex(
                    (value) => value.id === change.doc.id
                  );
                  draft.splice(draftIndex);
                });
              }
            });
          });
        } catch (error) {}

        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    uploadChatDocument: builder.mutation({
      queryFn: async ({
        chatId,
        senderId,
        data,
      }: {
        chatId: string;
        senderId: string;
        data: { content: string; attachment: File }[];
      }) => {
        try {
          const promises: any = [];
          data.forEach(({ content, attachment }) => {
            promises.push(
              uploadImageAndSendChatPromise(
                attachment,
                chatId,
                content,
                senderId
              )
            );
          });
          await Promise.all([...promises]);
          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },

      // providesTags: [{ type: RtkqTagEnum.CHAT_MESSAGE }],
    }),

    setOffline: builder.mutation({
      queryFn: async ({ id }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          const docRef = firestore.doc(firebaseFirestore, "users", id);
          await firestore.updateDoc(docRef, {
            isOnline: false,
          });

          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
    }),

    setOnline: builder.mutation({
      queryFn: async ({ id }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          const docRef = firestore.doc(firebaseFirestore, "users", id);
          await firestore.updateDoc(docRef, {
            isOnline: true,
          });

          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
    }),

    setUnreadMessagesToRead: builder.mutation({
      queryFn: async ({ chatId, receiverID }) => {
        try {
          const { firestore, firebaseFirestore } = await initializeFirebase();

          const q = firestore.query(
            firestore.collection(
              firebaseFirestore,
              "chats",
              chatId,
              "messages"
            ),
            firestore.where("receiverID", "==", receiverID),
            firestore.where("unRead", "==", true)
          );

          const querySnapshot = await firestore.getDocs(q);

          querySnapshot.docs.forEach(async (document) => {
            await firestore.updateDoc(document.ref, {
              ...document.data(),
              unRead: false,
            });
          });

          return { data: [] } as any;
        } catch (error) {
          return {
            error: {
              data: { defaultUserMessage: "Failed to fetch messages" },
              status: 500,
            },
          };
        }
      },
    }),
  }),
  overrideExisting: true,
});
