import { useCallback } from "react";
import { ChatApi } from "api/ChatApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onCapture, onReset } from "configs/PosthogConfig";
import { EVENTS } from "constants/GlobalConstant";
import { trackEvent } from "utils/trackUtils";
import { updateAppSessionInactiveAction } from "store/storeSlice";
import { logoutAction } from "../store/storeActions";
import useAuthUser from "./useAuthUser";
import useAppSessionId from "./useAppSessionId";

function useLogout(): { logout: () => void } {
  const authUser = useAuthUser();
  const { appSessionId } = useAppSessionId();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [setOffLine] = ChatApi.useSetOfflineMutation();

  const signoutPayload = {
    user_id: authUser?.id,
    email: authUser?.email,
  };

  const logout = useCallback(
    async function logout() {
      try {
        setOffLine({
          id: authUser.firebaseId,
        }).unwrap();
        onCapture(EVENTS.SIGNED_OUT, {
          ...signoutPayload,
          appSessionId,
          signed_out_date: new Date().toISOString(),
        });
        onReset();
        trackEvent(EVENTS.SIGNED_OUT, {
          ...authUser,
          ...signoutPayload,
          appSessionId,
          signed_out_date: new Date(),
        });
        trackEvent(EVENTS.APP_SESSION_ENDED, {
          ...authUser,
          ...signoutPayload,
          appSessionId,
          signed_out_date: new Date(),
        });
        localStorage.setItem("@appLaunchedBefore", "null");
        localStorage.removeItem("@ongoingAppSessionId");
        window.analytics.reset();
      } catch (error) {
        //
      } finally {
        dispatch(updateAppSessionInactiveAction(false));
        dispatch(logoutAction());
        navigate("/login");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUser?.email, authUser?.id, dispatch, navigate, appSessionId]
  );

  return { logout };
}

export default useLogout;
