import { SessionType } from "features/enums";
import { PaymentGateway } from "./currency";
import { ServerResponse } from "./global";

export enum SubscriptionPackageStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DISABLED = "DISABLED",
}

export enum SubscriptionIntervalType {
  MONTHLY = "MONTHLY",
  QUATERLY = "QUATERLY",
  YEARLY = "YEARLY",
}

export type SubscriptionPackage = {
  id: string;
  name: string;
  title: string;
  description: string;
  status: string;
  amount: number;
  discountPercentage: number | null;
  organizationId: number | null;
  interval: string;
  sessionCount: number;
  createdAt: string;
  updatedAt: string;
  subscriptionCodes: Array<{
    code: string;
    gateway: PaymentGateway;
    currency: string;
  }>;
  bgColor: string | null;
  textColor: string | null;
  features: {
    chatbot: boolean;
    webinar: boolean;
    selfHelp: boolean;
    community: boolean;
    messaging: boolean;
    assessment: boolean;
    newsletter: boolean;
    psychiatrist: boolean;
    familySession: boolean;
    familyWebinar: boolean;
    familyResources: boolean;
  } | null;
  order: number | null;
  sessionType: SessionType;
};

export type GetSubscriptionPackagesResponse = ServerResponse<{
  results: Array<SubscriptionPackage>;
  count: number;
  currentPage: number;
  pageCount: number;
}>;
export type GetSubscriptionPackagesRequest = {
  name?: string;
  status?: SubscriptionPackageStatus;
  interval?: SubscriptionIntervalType;
  sessionType: SessionType;
  limit?: string;
  page?: string;
};

export type InitializeSubscriptionResponse = ServerResponse<{
  paymentLink: string;
}>;
export type InitializeSubscriptionRequest = {
  subscriptionPackageId: string;
  currencyId: number;
  autoRenewal: boolean;
  redirectUrl: string;
};

export type GetActiveSubscriptionResponse = ServerResponse<{
  id: string;
  subscriptionPackage: {
    name: string;
    sessionCount: number;
    status: string;
    amount: number;
    id: string;
    interval: SubscriptionIntervalType;
    order: number;
  };
}>;
export type GetActiveSubscriptionRequest = {
  sessionType: SessionType;
};

export type VerifySubscriptionTransactionResponse = ServerResponse<{
  title: string;
  description: string;
}>;
export type VerifySubscriptionTransactionRequest = {
  params: { reference?: string; trxId?: string; sessionId?: string };
};

export type SubscriptionContentProps = {
  currentPlanName: string;
  currentPlanId: string;
  currentPlanAmount: string;
  currentPlanInterval: SubscriptionIntervalType | undefined;
  handleCancelSubscription: (data: any) => void;
  handleSubscriptionAction: (data: any) => void;
  buttonContent: (id: string) => string;
  sessionType: SessionType;
};

export type UnsubscribeSubscriptionResponse = ServerResponse<string>;

export type ActivateSubscriptionResponse = ServerResponse<string>;
