import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Link,
  Typography,
} from "@mui/material";
import config from "configs/config";
import { gotoEmailBox } from "utils/gotoEmailBox";
import useLogout from "hooks/useLogout";

interface ErrorDialogProps {
  title?: string;
  description?: string;
  onRetry?: () => void;
  retryText?: string;
}
/**
 *
 * @param {ErrorDialogProps} props
 */
export function ErrorDialog(props: ErrorDialogProps) {
  const { title, description, onRetry, retryText, ...rest } = props;
  const { logout } = useLogout();
  function handleRetry(e: any) {
    e.stopPropagation();
    if (onRetry) {
      onRetry();
    }
  }

  return (
    <Dialog open fullWidth {...rest}>
      <DialogTitle className="font-gelica">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={logout} color="error" variant="outlined">
            Reset
          </Button>
          <Button onClick={handleRetry}>{retryText}</Button>
        </>
      </DialogActions>
    </Dialog>
  );
}

ErrorDialog.defaultProps = {
  title: "Something went wrong",
  description: (
    <Typography>
      Sorry, something unexpected happened, Please try again or contact our
      support.{" "}
      <Link
        component="button"
        onClick={() => gotoEmailBox(config.AKOMA_HEALTH_SUPPORT_EMAIL)}
      >
        {config.AKOMA_HEALTH_SUPPORT_EMAIL}{" "}
      </Link>
    </Typography>
  ),
  retryText: "Refresh",
};

export default ErrorDialog;

/**
 * @typedef {import("./ErrorDialogContext").ErrorOptions & import("@material-ui/core").DialogProps} ErrorDialogProps
 */
