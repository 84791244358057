import { routeEnum } from "constants/RouteConstants";
import { onCapture, onIdentify } from "configs/PosthogConfig";
import { EVENTS } from "constants/GlobalConstant";
import { TransformTrackUserPayload } from "./trackUtils";

export const posthogIdentifier = (globalStatePayload) => {
  const trackLoginUserPayload = TransformTrackUserPayload(
    globalStatePayload,
    routeEnum.LOGIN
  );

  onIdentify(trackLoginUserPayload?.email, {
    ...trackLoginUserPayload,
  });

  onCapture(EVENTS.SIGNED_IN, {
    ...trackLoginUserPayload,
  });

  return trackLoginUserPayload;
};
