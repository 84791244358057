/* eslint-disable no-param-reassign */
// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { deepReplace } from "utils/helper";
import { FeatureFlagApi } from "api/FeatureFlagApi";
import { ApiPartnerStoreSlice } from "api/ApiPartnerSlice";
import { isObjectEmpty } from "utils/ObjectUtils";
import { type GlobalInitialStateType } from "../types/global";
import { logoutAction, updateAppSessionIdAction } from "./storeActions";
import { akomaHealthApi } from "./storeQuerySlice";
import { ApiUserStoreSlice } from "../api/ApiUserStoreSlice";

export const globalInitialState: GlobalInitialStateType = {
  authUser: null,
  isSideMenu: false,
  hasAvailability: null,
  hasBookedSession: null,
  isDashboardSpacing: true,
  channel: null,
  featureFlags: undefined,
  initiatedChatEventIds: [],
  appSessionId: null,
  appSessionInactive: null,
};

const slice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleSideMenuAction: (state, { payload }) => {
      state.isSideMenu = payload !== undefined ? !!payload : !state.isSideMenu;
    },
    updateChannelAction: (state, { payload }) => {
      state.channel = payload !== undefined ? payload : state.channel;
    },
    updateInitiatedChatEventIdsAction: (state, { payload }) => {
      state.initiatedChatEventIds =
        payload !== undefined ? payload : state.initiatedChatEventIds;
    },
    updateAppSessionInactiveAction: (state, { payload }) => {
      state.appSessionInactive =
        payload !== undefined ? payload : state.appSessionInactive;
    },

    updateProfileAction: (state, { payload }) => {
      // if (state.authUser.data) {
      state.authUser =
        payload !== undefined
          ? { ...state.authUser, ...payload.data }
          : state.authUser;
      // }
    },
    updateHasAvailability: (state, { payload }) => {
      state.authUser =
        payload !== undefined
          ? deepReplace(
              state.authUser,
              ["provider", "hasSetAvailability"],
              payload
            )
          : state.authUser;
    },
    updateHasBookedSession: (state, { payload }) => {
      state.authUser =
        payload !== undefined
          ? deepReplace(state.authUser, ["client", "hasBookedSession"], payload)
          : state.authUser;
    },
    updateHasSetPreferenceQuiz: (state, { payload }) => {
      state.authUser =
        payload !== undefined
          ? deepReplace(
              state.authUser,
              ["client", "hasSetPreferenceQuiz"],
              payload
            )
          : state.authUser;
    },
    updateProviderAccountStage: (state, { payload }) => {
      state.authUser =
        payload !== undefined
          ? { ...state.authUser, ...payload }
          : state.authUser.data;
    },
    updateChangedEmail: (state, { payload }) => {
      state.authUser =
        payload !== undefined
          ? {
              ...state.authUser, ...deepReplace(state.authUser.data, ["email"], payload),
            }
          : state.authUser;
    },
    toggleDashboardSpacingAction: (state, { payload }) => {
      state.isDashboardSpacing =
        payload !== undefined ? !!payload : !state.isDashboardSpacing;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))

      .addCase(updateAppSessionIdAction, (state, { payload }) => {
        state.appSessionId =
          payload !== undefined ? payload : state.appSessionId;
      })

      .addMatcher(
        akomaHealthApi.endpoints.authToken.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        akomaHealthApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        ApiUserStoreSlice.endpoints.getMe.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        akomaHealthApi.endpoints.loginPartner.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        akomaHealthApi.endpoints.loginWithMagicLink.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        ApiPartnerStoreSlice.endpoints.ValidatePartnerUsersMagicLink
          .matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        ApiUserStoreSlice.endpoints.updateUser.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        ApiUserStoreSlice.endpoints.getMe.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        akomaHealthApi.endpoints.verifyEmailOtp.matchFulfilled,
        (state, { payload }) => {
          const hmoDataExist = !isObjectEmpty(state.authUser?.hmoData || {});
          state.authUser = {
            ...state.authUser,
            ...payload?.data,
            ...(hmoDataExist
              ? { hmoData: { ...state.authUser?.hmoData } }
              : {}),
          } as any;
        }
      )

      .addMatcher(
        ApiPartnerStoreSlice.endpoints.updateSubUser.matchFulfilled,
        (state, { payload }) => {
          // @ts-ignore
          state.authUser = { ...state.authUser, hmoData: {} };
          //@ts-ignore
          state.authUser.hmoData.email = payload?.data?.email as any;
        }
      )

      .addMatcher(
        akomaHealthApi.endpoints.register.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload?.data };
        }
      )

      .addMatcher(
        ApiUserStoreSlice.endpoints.updateAlxUserSubscriptionStatus
          .matchFulfilled,
        (state: any, { payload }) => {
          //@ts-ignore
          state.authUser = { ...state.authUser, isSubscribed: true };
        }
      )
      .addMatcher(
        FeatureFlagApi.endpoints.getFeatureFlags.matchFulfilled,
        (state, { payload }) => {
          state.featureFlags = payload?.data;
        }
      ),
});

export const {
  toggleSideMenuAction,
  updateChannelAction,
  updateInitiatedChatEventIdsAction,
  updateAppSessionInactiveAction,
  updateProfileAction,
  updateHasAvailability,
  updateHasBookedSession,
  updateHasSetPreferenceQuiz,
  updateProviderAccountStage,
  updateChangedEmail,
  toggleDashboardSpacingAction,
} = slice.actions;

export default slice;

export function getGlobalSliceStorageState({
  authUser,
  channel,
  initiatedChatEventIds,
  appSessionId,
  appSessionInactive,
}: GlobalInitialStateType) {
  const rtqStateVariables = {
    authUser,
    channel,
    initiatedChatEventIds,
    appSessionId,
    appSessionInactive,
  };
  if (import.meta.env.VITE_NODE_ENV === "production") {
    return { ...rtqStateVariables };
  }
  return { ...rtqStateVariables };
}
