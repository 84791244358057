import {
  type UpdatePhotoRequest,
  type UpdatePhotoResponse,
  type UploadPhotoRequest,
  type UploadPhotoResponse,
} from "types/image";
import {
  ActivateProviderRequest,
  ActivateProviderResponse,
  AssignProviderRequest,
  AssignProviderResponse,
} from "types/provider";
import { RtkqTagEnum } from "../constants/strings";
import { akomaHealthApi } from "../store/storeQuerySlice";
import {
  type GetUserResponse,
  type GetMeResponse,
  type GetUserInfoRequest,
  type GetUserInfoResponse,
  type UpdateUserRequest,
  type UpdateUserResponse,
  SendNotificationResponse,
  SendNotificationRequest,
  UpdateUserFCMTokenResponse,
  GetUserFCMTokenResponse,
} from "../types/user";

export const ApiUserStoreSlice = akomaHealthApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<GetMeResponse, void>({
      query: () => ({
        url: "/api/v2/users/info",
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.USERS, RtkqTagEnum.AUTH],
    }),
    getUserInfo: builder.query<GetUserInfoResponse, GetUserInfoRequest>({
      query: ({ id }: GetUserInfoRequest) => ({
        url: `/api/v2/users/lookup/${id}`,
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.USERS],
    }),
    updateUser: builder.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: ({ ...data }: UpdateUserRequest) => ({
        url: "/api/v2/clients/update",
        data,
        method: "PUT",
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),
    updateUserPhoneNumber: builder.mutation<
      UpdateUserResponse,
      UpdateUserRequest
    >({
      query: ({
        ...data
      }: {
        phone?: string;
        isWhatsappEnabled?: boolean;
      }) => ({
        url: "/api/v2/users/update",
        data,
        method: "PUT",
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),
    assignProvider: builder.mutation<
      AssignProviderResponse,
      AssignProviderRequest
    >({
      query: ({ ...data }: AssignProviderRequest) => ({
        url: "/api/v2/clients/change-provider",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.USERS, RtkqTagEnum.AUTH],
    }),
    uploadPhoto: builder.mutation<UploadPhotoResponse, UploadPhotoRequest>({
      query: (data: UploadPhotoRequest) => ({
        url: "/api/v2/upload/file",
        data,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),
    updatePhoto: builder.mutation<UpdatePhotoResponse, UpdatePhotoRequest>({
      query: ({ ...data }: UpdatePhotoRequest) => ({
        url: "/api/v2/users/change-photo",
        data,
        method: "PUT",
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),
    getUser: builder.query<GetUserResponse, { id: string | undefined }>({
      query: ({ id }) => ({
        url: `/api/v2/users/lookup/${id}`,
      }),
      providesTags: [RtkqTagEnum.USERS],
    }),
    activateProvider: builder.mutation<
      ActivateProviderResponse,
      ActivateProviderRequest
    >({
      query: (data) => ({
        url: "/api/v2/providers/activate",
        data,
        method: "POST",
      }),
    }),
    getUserFCMToken: builder.query<GetUserFCMTokenResponse, void>({
      query: () => ({
        url: "/api/v2/fcmToken",
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.USERS, RtkqTagEnum.AUTH],
    }),
    sendNotification: builder.mutation<
      SendNotificationResponse,
      SendNotificationRequest
    >({
      query: ({ ...data }: SendNotificationRequest) => ({
        url: "/api/v2/users/send-notification",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.USERS, RtkqTagEnum.AUTH],
    }),

    updateUserFCMToken: builder.mutation<
      UpdateUserFCMTokenResponse,
      { fcmToken: string }
    >({
      query: ({ ...data }: { fcmToken: string }) => ({
        url: "/api/v2/users/fcmToken",
        data,
        method: "PUT",
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),

    updateAlxUserSubscriptionStatus: builder.mutation({
      query: () => ({
        url: "/api/v2/users/alx-subscription",
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.USERS],
    }),
  }),
  overrideExisting: false,
});
