import {
  type Palette,
  createTheme,
  responsiveFontSizes,
  type Theme,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// eslint-disable-next-line import/no-mutable-exports
export let theme: Theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 640,
  //     md: 768,
  //     lg: 1024,
  //     xl: 1280,
  //   },
  // },
});

theme = createTheme({
  palette: {
    common: {
      white: "#FFFFFF",
      black: "#1A1A1A",
    },
    primary: {
      lighter: "#F9FBFF",
      light: "#EBF8FF",
      main: "#0356B2",
      dark: "#001C50",
      contrastText: "#ffff",
    },

    secondary: {
      lighter: "#E4E7F1",
      light: "#E4E7F1",
      main: "#002F86",
      dark: "#00215E",
      contrastText: "#1A1A1A",
    },
    purple: {
      lighter: "#FBF3FF",
      light: "#F2DDFF",
      main: "#9E00FF",
      dark: "#5F0099",
      contrastText: "#fff",
    },
    maize: {
      lighter: "#FFFAEF",
      light: "#FFF0D1",
      main: "#F8C152",
      dark: "#957431",
      contrastText: "#fff",
    },
    yellow: {
      lighter: "#FFFEF0",
      light: "#FBF8D8",
      main: "#FFF23A",
      dark: "#CEC100",
      contrastText: "#fff",
    },
    pink: {
      lighter: "#FEF4F8",
      light: "#FFE2ED",
      main: "#F31E6C",
      dark: "#C21856",
      contrastText: "#fff",
    },
    richEBlue: {
      lighter: "#D4F2FF",
      light: "#80CEEF",
      main: "#009CDE",
      dark: "#006D9B",
      contrastText: "#fff",
    },
    carolinaBlue: {
      lighter: "#E4F1FF",
      light: "#BCD4EE",
      main: "#5893D4",
      dark: "#3E6794",
      contrastText: "#fff",
    },
    malachite: {
      lighter: "#EEFEF6",
      light: "#D1FBE6",
      main: "#20BF55",
      dark: "#16863B",
      contrastText: "#fff",
    },
    info: {
      light: "#74CAFF",
      main: "#0065FF",
      dark: "#0C53B7",
      contrastText: "#FFFFFF",
    },

    success: {
      lighter: "#F3FEF7",
      light: "#DFFFE9",
      main: "#038A30",
      dark: "#024518",
      contrastText: "#212B36",
    },
    warning: {
      lighter: "#FFF8EA",
      light: "#FFF1D3",
      main: "#FFAB00",
      dark: "#CC8900",
      contrastText: "#1A1A1A",
    },
    error: {
      lighter: "#FFF3F4",
      light: "#FFA48D",
      main: "#FB4B5B",
      dark: "#C93C49",
      contrastText: "#FFFFFF",
    },
    grey: {
      50: "#F2F2F2",
      100: "#767575",
      200: "#DBDBDB",
      300: "#6C6C6C",
      400: "#282B2F",
    },
    action: {
      active: "#637381",
      hover: "#919EAB14",
      selected: "#919EAB29",
      disabled: "#919EAB",
      disabledBackground: "#919EAB3D",
      focus: "#919EAB3D",
    },
    text: {
      primary: "#212B36",
      secondary: "#6C6C6C",
      disabled: "#919EAB",
    },
    divider: "#E4E7F1",
  },
  typography: {
    fontFamily: ["Public Sans", "sans-serif"].join(),
    fontSize: 12,
    fontWeightRegular: 500,
    fontWeightBold: 600,
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
    h1: {
      // fontSize: "2rem", // 32px
      fontSize: "28px", // 32px
    },
    h2: {
      // fontSize: "1.5rem", // 24px
      fontSize: "24", // 24px
    },
    h3: {
      // fontSize: "1.5rem", // 24px
      fontSize: "20", // 24px
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem", // 16 px
      },
    },
    h4: {
      fontSize: "20px", // 20px
      [theme.breakpoints.down("md")]: {
        fontSize: "0.938rem", // 15px
      },
    },
    body1: {
      // fontSize: "1.125rem", // 18px
      fontSize: "16px", // 18px
      color: "#282B2F",
    },
    body2: {
      // fontSize: "1rem", // 16px
      fontSize: "14px", // 16px
      [theme.breakpoints.down("md")]: {
        fontSize: "0.938rem", // 15px
      },
    },
    body3: {
      fontSize: "0.938rem", // 15px
      [theme.breakpoints.down("md")]: {
        fontSize: "0.813rem", // 13px
      },
    },
    caption: {
      fontSize: "0.875rem", // 14px
      [theme.breakpoints.down("md")]: {
        fontSize: "0.813rem", // 13px
      },
    },
    small: {
      fontSize: "0.7rem", // 12px
    },
    link: {
      fontSize: "0.938rem", // 15px
      color: "#0065FF",
      fontWeight: 600,
      textDecoration: "underline",
    },
  },
  zIndex: { modal: 50000 },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            "& .MuiInputBase-root": {
              borderRadius: "8px",
              background: "#FCFCFC",
              textTransform: "none",
              fontWeight: 500,
              fontSize: "16px",
              color: "#6C6C6C",
              border: ".8px solid #E4E7F1 !important",
            },

            ...(ownerState.size === "small"
              ? {
                  "& .MuiInputBase-root": {
                    fontSize: "8px !important",
                  },
                }
              : {}),
          };
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiBox-root": {
            borderRadius: 8,
            border: "none",
            background: "white",
            padding: "16px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 60,
          marginLeft: 30,
          "&.Mui-selected": {
            fontWeight: 600,
          },
        },
      },
      defaultProps: {
        sx: {
          typography: {
            fontSize: 16,
            padding: 3,
            paddingLeft: { xs: 40, md: 40 },
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          style: {
            borderRadius: 10,
          },
        },
      },
    },
    MuiPaper: {},
    MuiFab: {
      defaultProps: {
        variant: "circular",
        size: "small",
        style: {
          boxShadow: "none",
          background: "#F1F1F1",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
        style: {
          border: "1px solid #E4E7F1",
          borderRadius: "8px",
        },
      },
    },
    MuiBadge: {
      defaultProps: {
        style: {
          boxShadow: "none",
          background: "#F1F1F1",
        },
      },
    },

    // Button customization
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: 14,
        },
        sizeLarge: {
          fontSize: 16,
        },
      },
      defaultProps: {
        variant: "contained",
        disableElevation: true,
        style: {
          textTransform: "capitalize",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: 8,
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            borderRadius: 8,
            background: "#0356B2",
            color: "#FFFFFF",
            ":hover": {
              background: "#001C50",
            },
            ":focus": {
              background: "#002F86",
            },
            ":active": {
              background: "#002F86",
            },
          },
        },
        {
          props: { variant: "containedGrey" },
          style: {
            borderRadius: 8,
            background: "#F7F7F7",
            color: "#6C6C6C",
            fontSize: 14,
            ":hover": {
              backgroundColor: "#FFFFFF",
              color: "#6C6C6C",
            },
            ":focus": {
              backgroundColor: "#FFFFFF",
              color: "#6C6C6C",
            },
            ":active": {
              backgroundColor: "#FFFFFF",
              color: "#6C6C6C",
            },
            ":selected": {
              backgroundColor: "#FFFFFF",
              color: "#6C6C6C",
            },
          },
        },

        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 8,
          },
        },

        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 8,
          },
        },
        {
          props: { variant: "containedSecondary" },
          style: {
            borderRadius: 8,
            background: "#E4F1FF",
            color: "#038A30",
            ":hover": {
              background: "#E4F1FF",
              color: "#038A30",
            },
            ":focus": {
              background: "#E4F1FF",
              color: "#038A30",
            },
            ":active": {
              background: "#E4F1FF",
              color: "#038A30",
            },
          },
        },
        {
          props: { variant: "containedSuccess" },
          style: {
            borderRadius: 8,
            border: "1px solid #20BF55",
            background: "#EEFEF6",
            color: "#038A30",
            ":hover": {
              background: "#EEFEF6",
              color: "#038A30",
            },
            ":focus": {
              background: "#EEFEF6",
              color: "#038A30",
            },
            // ":active": {
            //   background: "#EEFEF6",
            //   color: "#038A30",
            // },
          },
        },
        {
          props: { variant: "containedInfo" },
          style: {
            borderRadius: 8,
            border: "1px solid #EBF8FF",
            background: "#EBF8FF",
            color: "#004898",
            ":hover": {
              background: "#EBF8FF",
              color: "#004898",
            },
            ":focus": {
              background: "#EBF8FF",
              color: "#004898",
            },
            // ":active": {
            //   background: "#EBF8FF",
            //   color: "#004898",
            // },
          },
        },
        {
          props: { variant: "text" },
          style: {
            borderRadius: 8,
          },
        },
        {
          props: { variant: "linkSecondary" },
          style: {
            borderRadius: "20px",
            background: "#F3F8FF",
            color: "#0065FF",
            textDecoration: "underline",
            ":hover": {
              opacity: 0.8,
              background: "#F3F8FF",
              textDecoration: "underline",
            },
            ":focus": {
              background: "#F3F8FF",
              opacity: 0.8,
              textDecoration: "underline",
            },
            ":active": {
              background: "#F3F8FF",
              opacity: 0.8,
              textDecoration: "underline",
            },
          },
        },
      ],
    },
    MuiChip: {
      defaultProps: {
        variant: "outlined",
        style: {
          fontSize: 14,
          border: "1px solid #E4E7F1",
        },
      },
      variants: [
        {
          props: { variant: "outlinedBox" },
          style: {
            backgroundColor: "transparent",
            borderRadius: 8,
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ".MuiFormControlLabel-label": {
            fontSize: "14px", // 16px
            [theme.breakpoints.down("md")]: {
              fontSize: "0.938rem", // 15px
            },
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);

// [
//   "primary",
//   "secondary",
//   "neutral",
//   "success",
//   "info",
//   "grey",
//   "warning",
//   "error",
//   "common",
//   "text",
//   "background",
//   "action",
// ].forEach((paletteKey) => {
//   if ((theme.palette as any)[paletteKey] !== undefined) {
//     Object.keys(theme.palette[paletteKey as keyof Palette]).forEach(
//       (palatteKeyColor) => {
//         document.documentElement.style.setProperty(
//           `--color-${paletteKey}-${palatteKeyColor}`,
//           (theme.palette as any)[paletteKey as keyof Palette][palatteKeyColor]
//         );
//       }
//     );
//   }
// });
