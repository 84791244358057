import { CircularProgress } from "@mui/material";
import React from "react";

/**
 *
 * @param {import("@mui/material").CircularProgressProps} props
 */
function LoadingIndicator(props: Record<string, any>) {
  return <CircularProgress {...props} />;
}

export default LoadingIndicator;
