/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck

import config from "configs/config";

declare global {
  interface Window {
    analytics: any[];
  }
}

// eslint-disable-next-line no-multi-assign
export const analytics = (window.analytics = window.analytics || []);

if (!analytics.initialize) {
  if (analytics.invoked) {
    window.console &&
      console.error &&
      console.error("Segment snippet included twice.");
  } else {
    analytics.invoked = !0;
    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
      "addSourceMiddleware",
      "addIntegrationMiddleware",
      "setAnonymousId",
      "addDestinationMiddleware",
    ];
    analytics.factory = function (e: string) {
      return function () {
        if (window.analytics.initialized) return window.analytics[e](...args);

        const i = Array.prototype.slice.call(arguments);
        i.unshift(e);
        analytics.push(i);
        return analytics;
      };
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    analytics.load = function (key: string, i: any) {
      const t = document.createElement("script");
      t.type = "text/javascript";
      t.async = !0;
      t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
      const n = document.getElementsByTagName("script")[0];
      n.parentNode?.insertBefore(t, n);
      analytics._loadOptions = i;
    };

    analytics._writeKey = config.SEGMENT_WRITE_KEY;
    analytics.SNIPPET_VERSION = "4.16.1";
    analytics.load(config.SEGMENT_WRITE_KEY);
    analytics.page();
  }
}
