interface TrackUserType {
  appSessionId: string;
  user_id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: string;
  path?: string;
  action?: string;
  userGroup: string;
  isOrg: boolean | null;
  orgId?: string;
  orgName?: string;
  isHmo: boolean;
  hmoUserId: string;
  hmoId: string;
  hmoName: string;
  isB2b: boolean;
  b2bUserId: boolean;
}

/**
 *
 * @param payload event properties
 * @param path url path (optional)
 * @returns transformed payload object
 */
export function TransformTrackUserPayload(payload, path?: string) {
  const payloadObject: TrackUserType = {
    appSessionId: payload?.appSessionId,
    user_id: payload?.id,
    firstName: payload?.firstName || "",
    lastName: payload?.lastName || "",
    email: payload?.email,
    phone: payload?.phone,
    gender: payload?.gender,
    path: path || "",
    action: payload?.action || "",
    isOrg: !!payload?.organization,
    ...(payload?.organization && {
      orgId: payload?.organizationId,
      orgName: payload?.organization?.name,
    }),
    isHmo: !!payload?.hmoData,
    ...(payload?.hmoData && {
      hmoUserId: payload?.hmoData?.id,
      hmoId: payload?.hmoData?.hmoId,
      hmoName: payload?.hmoData?.hmo?.name,
    }),
    isB2b:
      !payload?.organization && !payload?.organization && !payload?.hmoData,
    ...(!payload?.organization &&
      !payload?.organization &&
      !payload?.hmoData && {
        isB2b: true,
        b2bUserId: payload?.id,
      }),
    userGroup: payload?.userGroup,
  };

  return payloadObject;
}

/**
 *
 * @param name event name
 * @param payload event properties
 * @param path url path (optional)
 * @returns transformed payload object
 */
export const trackEvent = (name: string, payload, path?: string) => {
  const trackUserPayload = TransformTrackUserPayload(payload, path);

  window.analytics.track(name, {
    ...trackUserPayload,
  });

  return trackUserPayload;
};
