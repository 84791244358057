import config from "./config";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
};

const initializeFirebase = async () => {
  const [
    firebaseInit,
    firebaseAuth,
    messagingInit,
    firestore,
    firebaseStorage,
    firebaseMessaging,
  ] = await Promise.all([
    import("firebase/app"),
    import("firebase/auth"),
    import("firebase/messaging"),
    import("firebase/firestore"),
    import("firebase/storage"),
    import("firebase/messaging"),
  ]);

  // Import the functions you need from the SDKs you need
  const firebaseApp = firebaseInit.initializeApp(firebaseConfig);
  const firebaseGetAuth = firebaseAuth.getAuth(firebaseApp);
  const firebaseFirestore = firestore.getFirestore(firebaseApp);
  const messaging = messagingInit.getMessaging(firebaseApp);

  // if (global.firebase) {
  //   return global.firebase;
  // }

  // if (!firebaseInit) {
  //   return Promise.reject(new Error("loading error"));
  // }

  // global.firebase = firebaseInit;

  return {
    firebaseApp,
    firebaseGetAuth,
    firebaseAuth,
    messaging,
    firebaseFirestore,
    firebaseStorage,
    firebaseMessaging,
    firestore,
  };
};

export { initializeFirebase };
