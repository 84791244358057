import config from "configs/config";
import { useFeatureFlagEnabled as usePosthogFeatureFlagEnabled } from "posthog-js/react";
import { useSelector } from "react-redux";

function useFeatureFlagEnabled(flag: string): boolean | undefined {
  const featureFlags = useSelector((state: any) => state.global.featureFlags);
  const posthogEnabled = usePosthogFeatureFlagEnabled(flag);

  const enabled =
    typeof posthogEnabled !== "undefined"
      ? posthogEnabled
      : featureFlags?.[flag];

  return (
    config.VIRTUAL_NODE_ENV === "development" || import.meta.env.DEV || enabled
  );
}

export default useFeatureFlagEnabled;
