import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "configs/SegmentConfig";
import { init as SentryInit, Replay as SentryReplay } from "@sentry/react";
import config from "configs/config";
import posthog from "posthog-js";
import App from "./App";
import storeQ from "./store/storeQ";
import { setupInterceptors } from "./configs/HttpConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setupInterceptors(storeQ);

SentryInit({
  dsn: config.SENTRY_DSN,
  environment: config.VIRTUAL_NODE_ENV,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: config.SENTRY_REPLAY_SESSION_RATE,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: config.SENTRY_REPLAY_ERROR_RATE,

  integrations: [new SentryReplay()],
});

if (typeof window !== "undefined")
  if (import.meta.env.VITE_NODE_ENV === "production")
    posthog.init(config.PUBLIC_POSTHOG_KEY, {
      api_host: "https://app.posthog.com",
    });

root.render(
  <React.StrictMode>
    <Provider store={storeQ}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
