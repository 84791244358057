/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
import { RtkqTagEnum } from "constants/strings";
import dfnsFormat from "date-fns/format";

/**
 *
 * @param {string} tagType
 * @param {any[]} resultsWithIds
 * @param {{selectId: (arg: any) => string}} options
 * @returns
 */
export function provideTags(
  tagType: any,
  resultsWithIds: any[],
  error: boolean,
  options: any = {}
) {
  const { selectId = ({ id }) => id } = options;
  const listTag = { type: tagType };
  const result = error
    ? [RtkqTagEnum.ERROR]
    : Array.isArray(resultsWithIds)
    ? [
        ...resultsWithIds.map((result) => ({
          type: tagType,
          id: selectId(result || {}) || "",
        })),
        tagType,
      ]
    : typeof resultsWithIds === "object"
    ? [{ type: tagType, id: selectId(resultsWithIds) }]
    : [listTag];

  return result;
}

export function invalidateTags(tagType: any, ids?: any[], error?: boolean) {
  const result = error
    ? []
    : ids?.length
    ? [...ids.map((id) => ({ type: tagType, id }))]
    : [{ type: tagType }];

  return result;
}

export function generateAppSessionId(email: string) {
  if (!email) return undefined;
  return email.concat("|", dfnsFormat(new Date(), "yyyy-MM-dd|HH:mm:ss"));
}
