import { getTimeZones } from "@vvo/tzdb";
import { SubscriptionIntervalTypeMap } from "constants/GlobalConstant";

export const removeWhitespace = (value: string) => {
  return value.replace(/\s/g, "");
};

export const capitalizedFirstLetter = (word = "") => {
  if (!word.length) return word;
  const firstLetter = word?.charAt(0)?.toUpperCase();
  return firstLetter + word.slice(1);
};

export const normalizeLetter = (word = "") => {
  if (!word?.length) return word;
  const firstLetter = word?.charAt(0)?.toUpperCase();
  const remainingWords = word?.slice(1)?.toLowerCase();
  return firstLetter + remainingWords;
};

export const uniqueStringLength = (str: string) => {
  return Array.from(new Intl.Segmenter().segment(str)).length;
};

/**
 *
 * @param obj
 * @param path
 * @param value
 * @returns new copy of replaced object
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const deepReplace: any = (obj: {}[], path: any[], value: any) => {
  const [head, ...rest] = path;

  if (rest.length === 0) {
    // base case: we've reached the property to replace
    return {
      ...obj,
      [head]: value,
    };
  }

  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(head)) {
    // recursive case: keep traversing the object
    return {
      ...obj,
      [head]: deepReplace(obj[head], rest, value),
    };
  }

  // if the property doesn't exist, return the original object
  return obj;
};

export function getAgeRange(isoDateStr: Date): string {
  // Step 1: Parse the ISO date string into a Date object
  const birthDate: Date = new Date(isoDateStr);

  // Step 2: Subtract the Date object from the current Date object to get a time difference
  const timeDiff: number = new Date().getTime() - birthDate.getTime();

  // Step 3: Divide the total number of milliseconds in the time difference by the number of milliseconds in a year
  const age: number = Math.round(timeDiff / (365.25 * 24 * 60 * 60 * 1000));

  // Step 4: Calculate the age range
  const lowerBound: number = Math.floor(age / 10) * 10;
  const upperBound: number = lowerBound + 9;

  return `${lowerBound} - ${upperBound}`;
}

const timeZones = getTimeZones();

export const getMyTimeZoneInfo = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const value = timeZones.find(
    (timeZone) =>
      userTimeZone === timeZone.name || timeZone.group.includes(userTimeZone)
  );

  return `${value?.name} (GMT ${value?.currentTimeFormat.split(" ")[0]})`;
};

export const isArray = (data: unknown) =>
  Object.prototype.toString.call(data) === "[object Array]";

export const removeEmptyStringFromArr = (_arrayOfString: string[]) => {
  const removeEmptyStrings = _arrayOfString.filter((item) => item !== "");
  return removeEmptyStrings;
};

export function normalizeString(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) =>
      index === 0 ? word.toUpperCase() : word.toLowerCase()
    )
    .replace(/\s+/g, "");
}

export function convertSubscriptionIntervalType(value: string) {
  switch (value) {
    case "MONTHLY":
      return SubscriptionIntervalTypeMap.MONTHLY;
    case "QUATERLY":
      return SubscriptionIntervalTypeMap.QUATERLY;
    case "YEARLY":
      return SubscriptionIntervalTypeMap.YEARLY;
    default:
      return "Invalid subscription interval type";
  }
}
