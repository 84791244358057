import { useSelector } from "react-redux";
import { isObjectEmpty } from "utils/ObjectUtils";
import { type AuthUserType } from "../types/global";

function useAuthUser(): AuthUserType & {
  isHmoUser: boolean;
  isOrganizationUser: boolean;
  isB2BUser: boolean;
  isB2CUser: boolean;
} {
  const authUser = useSelector((state: any) => state?.global?.authUser);
  const isHmoUser = !isObjectEmpty(authUser?.hmoData || {});
  const isOrganizationUser = !isObjectEmpty(authUser?.organization || {});

  const isB2BUser = isHmoUser || isOrganizationUser;
  const isB2CUser = !isHmoUser && !isOrganizationUser;

  return authUser
    ? {
        ...authUser,
        isHmoUser,
        isOrganizationUser,
        isB2BUser,
        isB2CUser,
      }
    : null;
}

export default useAuthUser;
