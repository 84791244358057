import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import IconFab from "common/IconFab";
import themeConfig from "configs/theme.config";
import React from "react";
import { Iconly } from "libs/react-iconly";
import Loop from "@mui/icons-material/Loop";

interface ErrorContentType {
  icon?: string | React.ReactElement;
  title?: string;
  data?: unknown[];
  headerTitle?: string;
  className?: string;
  description?: string;
  header?: React.ReactNode;
  variant?: "error" | "primary";
  children?: React.ReactNode;
  onReload?: () => void;
}

function ErrorContent(props: ErrorContentType): JSX.Element {
  const {
    icon,
    title,
    header,
    headerTitle,
    description,
    variant,
    children,
    onReload,
    className,
    ...rest
  } = props;

  return (
    <Card variant="outlined" {...rest}>
      <CardContent>
        {headerTitle ? (
          <>
            <Typography
              variant="body1"
              className="capitalize mb-4"
              fontWeight="bold"
            >
              {headerTitle}
            </Typography>
            <Divider />
          </>
        ) : null}
        <div className="flex justify-center items-center flex-col min-h-[300px] w-full">
          {header}
          <IconFab
            size="large"
            disabled
            color={`${
              // eslint-disable-next-line no-nested-ternary
              variant === "error"
                ? themeConfig.palette.error.lighter
                : variant === "primary"
                ? "#EBF8FF"
                : "#EBF8FF"
            }`}
          >
            {typeof icon === "string" ? (
              <Iconly
                filled
                name={icon}
                set="bold"
                size={30}
                primaryColor={`${
                  // eslint-disable-next-line no-nested-ternary
                  variant === "error"
                    ? themeConfig.palette.error.light
                    : variant === "primary"
                    ? themeConfig.palette.primary.dark
                    : themeConfig.palette.primary.dark
                }`}
              />
            ) : (
              <div>{icon}</div>
            )}
          </IconFab>
          <Typography
            variant="body1"
            className="mt-4 text-center font-gelica"
            fontWeight={600}
          >
            {title}
          </Typography>
          <Typography
            className="mt-2 text-center"
            variant="body2"
            style={{ color: "#6C6C6C" }}
          >
            {description}
          </Typography>

          {onReload && (
            <div>
              <Tooltip title="Refresh">
                <IconButton size="large" onClick={onReload} title="Refresh">
                  <Loop fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <div className="w-full max-w-xs mt-4 inline-flex justify-center">
            {children}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

ErrorContent.defaultProps = {
  icon: "Danger",
  variant: "error",
  title: "Something went wrong",
  description: "We're quite sorry about this!",
};

export default ErrorContent;
