import React from "react";
import { CircularProgress } from "@mui/material";
import Logo from "./Logo";

export default function AkomaLoadingIndicator() {
  return (
    <div className="flex flex-col justify-center items-center gap-3">
      <Logo style={{ width: "100%", maxWidth: 100 }} />
      <CircularProgress size={20} />
    </div>
  );
}
