import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axios-base-request";
import { initializeFirebase } from "configs/firebase.config";
import { posthogIdentifier } from "utils/posthogUtils";
import { segmentIdentifier } from "utils/segmentUtils";
import { generateAppSessionId } from "utils/QueryUtils";
import { RtkqTagEnum } from "../constants/strings";
import { AkomaHttp } from "../configs/HttpConfig";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  AuthTokenRequest,
  AuthTokenResponse,
  ChangeEmailRequest,
  ChangeEmailResponse,
  CheckEmailRequest,
  CheckEmailResponse,
  CompletePasswordResetRequest,
  CompletePasswordResetResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  ResendResetPasswordRequest,
  ResendResetPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  VerificationOtpRequest,
  VerificationOtpResponse,
  VerifyEmailOtpRequest,
  VerifyEmailOtpResponse,
  LoginWithMagicLinkResponse,
  LoginWithMagicLinkRequest,
  SendMagicLinkRequest,
  SendMagicLinkResponse,
} from "../types/auth";
import { updateAppSessionIdAction } from "./storeActions";

export const akomaHealthApi = createApi({
  reducerPath: "akoma",
  baseQuery: axiosBaseQuery({}, AkomaHttp),
  tagTypes: [RtkqTagEnum.AUTH, RtkqTagEnum.USERS],
  endpoints: (builder) => ({
    checkEmail: builder.query<CheckEmailResponse, CheckEmailRequest>({
      query: (email: CheckEmailRequest) => ({
        url: `/api/v2/auth/check-email/${email}`,
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.AUTH],
    }),
    register: builder.mutation<RegisterResponse, RegisterRequest>({
      query: ({ ...data }: RegisterRequest) => ({
        url: "/api/v2/auth/clients/signup",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    verifyEmailOtp: builder.mutation<
      VerifyEmailOtpResponse,
      VerifyEmailOtpRequest
    >({
      query: ({ ...data }: VerifyEmailOtpRequest) => ({
        url: "/api/v2/auth/clients/verify-email-otp",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    changeEmail: builder.mutation<ChangeEmailResponse, ChangeEmailRequest>({
      query: ({ ...data }: ChangeEmailRequest) => ({
        url: "/api/v2/auth/clients/change-email",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordRequest
    >({
      query: ({ ...data }: ChangePasswordRequest) => ({
        url: "/api/v2/auth/change-password",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    verificationOtp: builder.query<
      VerificationOtpResponse,
      VerificationOtpRequest
    >({
      query: ({ email, ...params }: VerificationOtpRequest) => ({
        url: `/api/v2/auth/clients/get-verification-otp/${email}`,
        method: "GET",
        params,
      }),
      providesTags: [RtkqTagEnum.AUTH],
    }),
    resetPassword: builder.query<ResetPasswordResponse, ResetPasswordRequest>({
      query: (email: ResetPasswordRequest) => ({
        url: `/api/v2/auth/reset-password/${email}`,
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.AUTH],
    }),
    resendResetPassword: builder.query<
      ResendResetPasswordResponse,
      ResendResetPasswordRequest
    >({
      query: (email: ResendResetPasswordRequest) => ({
        url: `/api/v2/auth/reset-password/${email}`,
        method: "GET",
      }),
      providesTags: [RtkqTagEnum.AUTH],
    }),
    completePasswordReset: builder.mutation<
      CompletePasswordResetResponse,
      CompletePasswordResetRequest
    >({
      query: ({ ...data }: CompletePasswordResetRequest) => ({
        url: "/api/v2/auth/complete-password-reset",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    loginPartner: builder.query<LoginResponse, string>({
      query: (token: string) => ({
        url: `/api/v2/partner/user/${token}`,
        method: "GET",
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const data = await queryFulfilled;

          const appSessionId = generateAppSessionId(data?.data?.data?.email);
          dispatch(updateAppSessionIdAction(appSessionId as void));

          const trackUserPayload = {
            ...data?.data?.data,
            ...{ appSessionId },
          };

          posthogIdentifier(trackUserPayload);
          segmentIdentifier(trackUserPayload);

          // eslint-disable-next-line no-empty
        } catch (error) {}
      },
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ ...data }: LoginRequest) => ({
        url: "/api/v2/auth/login",
        data,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { firebaseAuth } = await initializeFirebase();
          const data = await queryFulfilled;

          const appSessionId = generateAppSessionId(data?.data?.data?.email);
          dispatch(updateAppSessionIdAction(appSessionId as void));

          const trackUserPayload = {
            ...data?.data?.data,
            ...{ appSessionId },
          };

          posthogIdentifier(trackUserPayload);
          segmentIdentifier(trackUserPayload);

          await firebaseAuth.signInWithCustomToken(
            firebaseAuth.getAuth(),
            data?.data?.data?.token as string
          );

          // eslint-disable-next-line no-empty
        } catch (error) {}
      },
      invalidatesTags: [RtkqTagEnum.AUTH, RtkqTagEnum.FEATURE_FLAG],
    }),
    loginWithMagicLink: builder.mutation<
      LoginWithMagicLinkResponse,
      LoginWithMagicLinkRequest
    >({
      query: ({ ...data }: LoginWithMagicLinkRequest) => ({
        url: "/api/v2/auth/login/magic-link",
        data,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { firebaseAuth } = await initializeFirebase();

          const data = await queryFulfilled;

          const appSessionId = generateAppSessionId(data?.data?.data?.email);
          dispatch(updateAppSessionIdAction(appSessionId as void));

          const trackUserPayload = {
            ...data?.data?.data,
            ...{ appSessionId },
          };

          posthogIdentifier(trackUserPayload);
          segmentIdentifier(trackUserPayload);

          await firebaseAuth.signInWithCustomToken(
            firebaseAuth.getAuth(),
            data?.data?.data?.token as string
          );
          // eslint-disable-next-line no-empty
        } catch (error) {}
      },
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    sendMagicLink: builder.mutation<
      SendMagicLinkResponse,
      SendMagicLinkRequest
    >({
      query: ({ ...data }: SendMagicLinkRequest) => ({
        url: "/api/v2/auth/send-magic-link",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    authToken: builder.mutation<AuthTokenResponse, AuthTokenRequest>({
      query: ({ ...data }: AuthTokenRequest) => ({
        url: "/api/v2/auth/get-auth-token",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: "/auth/logout",
        data,
      }),
    }),
  }),
});

[akomaHealthApi].forEach((api) => {
  api.enhanceEndpoints({ addTagTypes: Object.values(RtkqTagEnum) });
});
