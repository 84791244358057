/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from "react-redux";
import { updateAppSessionIdAction } from "store/storeActions";
import { updateAppSessionInactiveAction } from "store/storeSlice";
import { generateAppSessionId } from "utils/QueryUtils";
import useAuthUser from "./useAuthUser";

function useAppSessionId(): {
  appSessionId: string | null;
  isAppSessionInactive: boolean;
  generateNewAppSessionId: (condition: boolean) => void | string;
} {
  let appSessionId;

  const user = useAuthUser();
  const dispatch = useDispatch();

  appSessionId = useSelector((state: any) => state?.global?.appSessionId);
  const appSessionInactive = useSelector(
    (state: any) => state?.global?.appSessionInactive
  );

  // IF SESSION INACTIVE, CREATE NEW APP-SESSION-ID
  if (appSessionInactive) {
    appSessionId = generateAppSessionId(user.email);
    dispatch(updateAppSessionInactiveAction(null));
  }

  const generateNewAppSessionId = (condition: boolean) => {
    if (condition) {
      appSessionId = generateAppSessionId(user.email);
      dispatch(updateAppSessionIdAction(appSessionId));
    }

    return appSessionId;
  };

  return {
    appSessionId: appSessionId || null,
    isAppSessionInactive: appSessionInactive,
    generateNewAppSessionId,
  };
}

export default useAppSessionId;
