import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import IconFab from "common/IconFab";
import themeConfig from "configs/theme.config";
import React from "react";
import { Iconly } from "libs/react-iconly";
import Loop from "@mui/icons-material/Loop";

export default function EmptyContentCard(props: {
  icon?: string;
  title?: string;
  headerTitle?: string;
  description?: string;
  header?: React.ReactNode;
  variant?: "error" | "primary";
  children?: React.ReactNode;
  onRefresh?: () => void;
  cardVariant?: any;
  [key: string]: string | React.ReactNode | (() => any);
}) {
  const {
    icon,
    title,
    header,
    headerTitle,
    description,
    cardVariant,
    variant,
    children,
    onRefresh,
    ...rest
  } = props;

  return (
    <Card variant={cardVariant ?? "outlined"} {...rest}>
      <CardContent className="h-full">
        {headerTitle ? (
          <>
            <Typography
              variant="body1"
              className="capitalize mb-4"
              fontWeight="bold"
            >
              {headerTitle}
            </Typography>
            <Divider />
          </>
        ) : null}
        <div className="flex justify-center items-center flex-col min-h-[300px] h-full w-full">
          {header}
          <IconFab
            size="large"
            disabled
            color={`${
              // eslint-disable-next-line no-nested-ternary
              variant === "error"
                ? themeConfig.palette.error.lighter
                : variant === "primary"
                ? "#EBF8FF"
                : "#EBF8FF"
            }`}
          >
            <Iconly
              filled
              name={icon}
              set="bold"
              size={30}
              primaryColor={`${
                // eslint-disable-next-line no-nested-ternary
                variant === "error"
                  ? themeConfig.palette.error.light
                  : variant === "primary"
                  ? themeConfig.palette.primary.dark
                  : themeConfig.palette.primary.dark
              }`}
            />
          </IconFab>
          <Typography
            variant="body1"
            className="mt-4 items-center text-center"
            fontWeight={600}
          >
            {title}
          </Typography>
          <Typography
            className="mt-2 text-center"
            variant="body1"
            style={{ color: "#6C6C6C" }}
          >
            {description}
          </Typography>

          {onRefresh && (
            <div>
              <Tooltip title="Refresh">
                <IconButton size="large" onClick={onRefresh} title="Refresh">
                  <Loop fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          )}

          <div className="w-full max-w-xs mt-6 item inline-flex justify-center">
            {children}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

EmptyContentCard.defaultProps = {
  icon: "Paper",
  title: "Empty Content",
  variant: "error",
  description: "No data available",
};
