import { PartnerRequest, PartnerResponse } from "types/partner";
import {
  SendMagicLinkToPartnerUsersRequest,
  SendMagicLinkToPartnerUsersResponse,
  UpdateSubUserRequest,
  ValidatePartnerUsersMagicLinkRequest,
  ValidatePartnerUsersMagicLinkResponse,
} from "types/auth";
import { initializeFirebase } from "configs/firebase.config";
import { RtkqTagEnum } from "../constants/strings";
import { akomaHealthApi } from "../store/storeQuerySlice";

export const ApiPartnerStoreSlice = akomaHealthApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<PartnerResponse, PartnerRequest>({
      query: ({ ...params }) => ({
        url: `/api/v2/partner/hmo`,
        method: "GET",
        params,
      }),
      providesTags: [RtkqTagEnum.PARTNER],
    }),
    sendMagicLinkToPartnerUsers: builder.mutation<
      SendMagicLinkToPartnerUsersResponse,
      SendMagicLinkToPartnerUsersRequest
    >({
      query: ({ ...data }: SendMagicLinkToPartnerUsersRequest) => ({
        url: "/api/v2/partner/users/send-magic-link",
        data,
        method: "POST",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),

    updateSubUser: builder.mutation<any, any>({
      query: ({ ...data }: UpdateSubUserRequest) => ({
        url: "/api/v2/partner/users/sub-user",
        data,
        method: "PUT",
      }),
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
    ValidatePartnerUsersMagicLink: builder.mutation<
      ValidatePartnerUsersMagicLinkResponse,
      ValidatePartnerUsersMagicLinkRequest
    >({
      query: ({ ...data }: ValidatePartnerUsersMagicLinkRequest) => ({
        url: "/api/v2/partner/users/validate-magic-link",
        data,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { firebaseAuth } = await initializeFirebase();

          const data = await queryFulfilled;
          await firebaseAuth.signInWithCustomToken(
            firebaseAuth.getAuth(),
            data?.data?.data?.token as string
          );
          // eslint-disable-next-line no-empty
        } catch (error) {}
      },
      invalidatesTags: [RtkqTagEnum.AUTH],
    }),
  }),
  overrideExisting: false,
});
