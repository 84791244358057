import * as Sentry from "@sentry/react";
import ErrorDialog from "./ErrorDialog";

export default function SentryErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorDialog
          onRetry={() => {
            window.location.assign("/");
          }}
        />
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
