import { RtkqTagEnum } from "constants/strings";
import { akomaHealthApi } from "../store/storeQuerySlice";

const FEATURE_FLAG_API_BASE_URL = "/api/v2/feature-flags";

export const FeatureFlagApi = akomaHealthApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query({
      query: (config) => ({
        url: `${FEATURE_FLAG_API_BASE_URL}`,
        ...config,
      }),
      providesTags: [RtkqTagEnum.FEATURE_FLAG],
    }),
  }),
});
