/* eslint-disable @typescript-eslint/no-use-before-define */
import { configureStore, type Store } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import CryptoJS from "crypto-js";
import config from "configs/config";
import globalSlice, {
  getGlobalSliceStorageState,
  globalInitialState,
} from "./storeSlice";
import { akomaHealthApi } from "./storeQuerySlice";
import { deepMerge, isObjectEmpty, throttle } from "../utils/store";
import { logoutAction } from "./storeActions";

const isDevelopment = import.meta.env.VITE_NODE_ENV === "development";

function getLocalStorageState() {
  const serializedState = localStorage.getItem("@state");
  if (serializedState) {
    return JSON.parse(
      isDevelopment
        ? serializedState
        : CryptoJS.AES.decrypt(
            serializedState,
            config.AES_ENCRYPTION_KEY
          ).toString(CryptoJS.enc.Utf8)
    );
  }
  return null;
}

function loadState(initialState = {}) {
  try {
    const newState = { ...initialState };
    const storageState = getLocalStorageState();
    if (storageState && !isObjectEmpty(storageState)) {
      Object.assign(newState, deepMerge(newState, storageState));
    }
    return newState;
  } catch (error) {
    //
  }
  return undefined;
}

const storeQ = configureStore({
  reducer: {
    [globalSlice.name]: globalSlice.reducer,
    [akomaHealthApi.reducerPath]: akomaHealthApi.reducer,
  },
  preloadedState: loadState({
    [globalSlice.name]: globalInitialState,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      akomaHealthApi.middleware,
      rtkqOnResetMiddleware(akomaHealthApi)
    ),

  devTools: isDevelopment,
});

setupListeners(storeQ.dispatch);

function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(
      "@state",
      isDevelopment
        ? serializedState
        : CryptoJS.AES.encrypt(serializedState, config.AES_ENCRYPTION_KEY)
    );
  } catch (error) {
    //
  }
}

storeQ.subscribe(
  throttle(() => {
    const state = storeQ.getState();
    saveState({
      [globalSlice.name]: getGlobalSliceStorageState(state[globalSlice.name]),
    });
  }, 1000)
);

export function rtkqOnResetMiddleware(...apis: any[]) {
  return (store) => (next) => (action) => {
    const result = next(action);
    if (logoutAction.match(action)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const api of apis) {
        store.dispatch(api.util.resetApiState());
      }
    }
    return result;
  };
}

export default storeQ;
