import { CardActionArea, CardActionAreaProps } from "@mui/material";
import React from "react";

export default function IconFab({
  variant,
  children,
  color,
  className,
  size,
  ...rest
}: {
  variant?: "circular" | "rectangle";
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  color: string;
  className?: string;
} & CardActionAreaProps) {
  return (
    <CardActionArea
      className={`
       ${variant === "circular" && "rounded-full"} 
       ${variant === "rectangle" && "rounded-lg"} 
     

       ${size === "small" && "md:w-6 w-4 md:h-6 h-4"} 
       ${size === "medium" && "md:w-8 w-6 md:h-8 h-6"} 
       ${size === "large" && "md:h-14 h-12 md:w-14 w-12"} 

       ${
         className && className
       } flex   p-1 justify-center items-center text-xs`}
      style={{ backgroundColor: `${color}` }}
      {...rest}
    >
      {children}
    </CardActionArea>
  );
}

IconFab.defaultProps = {
  color: "#F2F2F2",
  variant: "circular",
  size: "medium",
};
