import { initializeFirebase } from "configs/firebase.config";

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getFileExtension(fileName: string) {
  return fileName?.split(".")?.pop();
}

export async function uploadImageAndSendChatPromise(
  file: File,
  chatId: string,
  content: string,
  senderId: string
) {
  const { firestore, firebaseStorage, firebaseFirestore } =
    await initializeFirebase();
  const { addDoc, collection, getDoc } = firestore;
  const { getDownloadURL, getStorage, ref, uploadBytesResumable } =
    firebaseStorage;

  return new Promise(function (resolve, reject) {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `chats/${chatId}/${new Date().valueOf()}-${file.name}`
    );
    const metadata = {
      cacheControl: "public,max-age=4000",
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
      },
      (error) => {
        reject(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          const docRef = await addDoc(
            collection(firebaseFirestore, "chats", chatId, "messages"),
            {
              attachment: downloadURL,
              content,
              senderId,
              createdAt: Date.now(),
            }
          );
          const docSnapshot = await getDoc(docRef);
          if (!docSnapshot.exists()) {
            throw new Error();
          }
          resolve({
            data: { id: docRef.id, ...docSnapshot.data() },
          } as any);
        });
      }
    );
  });
}

export function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(",");
  //@ts-ignore
  const mime = arr?.[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  //@ts-ignore
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const fetchImage = async (imageUrl: string, cb) => {
  const res = await fetch(imageUrl);
  console.log({ res });
  const imageBlob = await res.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  cb(imageObjectURL);
  return imageObjectURL;
};
