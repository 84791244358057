import clsx from "clsx";
import { S3_BASE_URL } from "constants/GlobalConstant";

const AkomaLogo = `${S3_BASE_URL}/assets/website/svgs/AkomaSVGLogo.svg`;

/**
 *
 * @param {import("react").ComponentPropsWithoutRef<typeof AkomaSVGLogo>} props
 */
function Logo(props: any) {
  return (
    <img
      loading="lazy"
      src={AkomaLogo}
      {...props}
      className={clsx(props?.className ? props?.className : "", "w-full")}
      alt="Logo"
    />
  );
}

export default Logo;
