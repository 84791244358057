import { routeEnum } from "constants/RouteConstants";
import { EVENTS } from "constants/GlobalConstant";
import { TransformTrackUserPayload } from "./trackUtils";

export const segmentIdentifier = (payload) => {
  const trackUserPayload = TransformTrackUserPayload(payload, routeEnum.LOGIN);

  window.analytics.identify(trackUserPayload?.email, {
    ...trackUserPayload,
  });

  window.analytics.track(EVENTS.SIGNED_IN, {
    ...trackUserPayload,
    signed_in_date: new Date(),
  });

  window.analytics.track(EVENTS.APP_SESSION_STARTED, {
    ...trackUserPayload,
  });

  return trackUserPayload;
};
