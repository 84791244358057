export const VERIFIED_EMAIL_KEY = "verifiedEmail";

export const RtkqTagEnum = {
  ERROR: "ERROR",
  AUTH: "AUTH",
  USERS: "USERS",
  PROVIDERS: "PROVIDERS",
  PROVIDER_AVAILABILITY_RULE: "PROVIDER_AVAILABILITY_RULE",
  PROVIDER_AVAILABILITY: "PROVIDER_AVAILABILITY",
  SESSIONS: "SESSIONS",
  PRICES: "PRICES",
  SESSIONS_REQUEST: "SESSIONS_REQUEST",
  ASSIGNED_CLIENT: "ASSIGNED_CLIENT",

  CLIENT_ANALYTICS: "CLIENT_ANALYTICS",
  CLIENT_PREFERENCE: "CLIENT_PREFERENCE",
  COUNTRY: "COUNTRY",
  STATE: "STATE",
  PHARMACY_LOCATION: "PHARMACY_LOCATION",
  PHARMACY: "PHARMACY",
  DIAGNOSIS: "DIAGNOSIS",
  PAST_SESSIONS: "PAST_SESSIONS",
  CANCELED_SESSIONS: "CANCELED_SESSIONS",
  PARTNER: "PARTNER",
  SUBSCRIPTION: "SUBSCRIPTION",

  HMO: "HMO",
  COUPON: "COUPON",
  PREFERENCE_QUIZ: "PREFERENCE_QUIZ",
  RECOMMENDED_THERAPISTS: "RECOMMENDED_THERAPISTS",
  CHAT: "CHAT",
  CHAT_MESSAGE: "CHAT_MESSAGE",
  LAST_MESSAGE: "LAST_MESSAGE",
  REVIEWS: "REVIEWS",
  CURRENCY: "CURRENCY",

  FEATURE_FLAG: "FEATURE_FLAG",
  GIFT: "GIFT",
  PROVIDER_REFERRAL: "PROVIDER_REFERRAL",
  REFERRAL: "REFERRAL",
};
