import React from 'react'
import AkomaLoadingIndicator from './AkomaLoadingIndicator'

export default function AppLoader() {
  return (
    <div className="flex flex-col gap-4 h-screen w-screen justify-center items-center">
     <AkomaLoadingIndicator />
     </div>
  )
}
